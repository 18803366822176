import { hourLabel } from "./interviewUtils"
import { isLateralMode, isInternalMode } from "utils/productUtils"
import { getCurrentRecruitmentYear, isHREnabled } from "utils/utils"
import { getFormConfigurationFromOrganization } from "utils/configUtils"

export const RECAPTCHA_SITEKEY = "6LesIoodAAAAAHRTAoHDDp6OWhWev5iF97bZ7Tp5"
export const RECAPTCHA_BYPASS_PARAM = "a6de8532-6478-420b-ad31-171ef0aaf6d0"

export const CAMBRIDGE_UNIVERSITY_ID = "23"
export const OXFORD_UNIVERSITY_ID = "95"

const PENDING = "PENDING"
const INVITED = "INVITED"
const SCHEDULED = "SCHEDULED"
const IN_PROGRESS = "IN_PROGRESS"
const COMPLETED = "COMPLETED"

export const CODESTAGES = {
  PENDING: PENDING,
  INVITED: INVITED,
  SCHEDULED: SCHEDULED,
  IN_PROGRESS: IN_PROGRESS,
  COMPLETED: COMPLETED,
}

const APPLIED = "1"
const AT_ONLINE_TEST = "2"
const AT_INTERVIEW = "3"
const AT_VIDEO_INTERVIEW = "4"
const AT_PHONE_INTERVIEW = "5"
const AT_1ST_ROUND_INTERVIEW = "6"
const AT_2ND_ROUND_INTERVIEW = "7"
const AT_WATSON_GLASER_TEST = "8"
const AT_ASSESSMENT_CENTRE = "9"
const AT_FINAL_ROUND = "10"
const OFFER_MADE = "11"
const OFFER_ACCEPTED = "14"
const WITHDREW = "15"
const REJECTED = "16"
const INTEREST_SHOWN = "17"
const DRAFT_PROMISED = "18"
const DRAFT_RECEIVED = "19"
const CHECKED_AT_APP_SLAM = "20"
const APPLIED_WITHOUT_APP_REVIEW = "23"
const APPROACHED = "24"
const AT_ONLINE_APPLICATION_FORM = "25"
const FOR_REVIEW = "28"

export const STAGES = {
  APPLIED: APPLIED,
  AT_ONLINE_TEST: AT_ONLINE_TEST,
  AT_INTERVIEW: AT_INTERVIEW,
  AT_VIDEO_INTERVIEW: AT_VIDEO_INTERVIEW,
  AT_PHONE_INTERVIEW: AT_PHONE_INTERVIEW,
  AT_1ST_ROUND_INTERVIEW: AT_1ST_ROUND_INTERVIEW,
  AT_2ND_ROUND_INTERVIEW: AT_2ND_ROUND_INTERVIEW,
  AT_WATSON_GLASER_TEST: AT_WATSON_GLASER_TEST,
  AT_ASSESSMENT_CENTRE: AT_ASSESSMENT_CENTRE,
  AT_FINAL_ROUND: AT_FINAL_ROUND,
  OFFER_MADE: OFFER_MADE,
  OFFER_ACCEPTED: OFFER_ACCEPTED,
  WITHDREW: WITHDREW,
  REJECTED: REJECTED,
  INTEREST_SHOWN: INTEREST_SHOWN,
  DRAFT_PROMISED: DRAFT_PROMISED,
  DRAFT_RECEIVED: DRAFT_RECEIVED,
  CHECKED_AT_APP_SLAM: CHECKED_AT_APP_SLAM,
  APPLIED_WITHOUT_APP_REVIEW: APPLIED_WITHOUT_APP_REVIEW,
  APPROACHED: APPROACHED,
  AT_ONLINE_APPLICATION_FORM: AT_ONLINE_APPLICATION_FORM,
  FOR_REVIEW: FOR_REVIEW,
}

export const MESSAGES = {
  /*"profile:publish": {
    done: "Your profile has been updated."
  },*/
  "export:csv": {
    done: "Export successful. Downloading csv file to your filesystem.",
  },
  "user:password:update": {
    done: "Password updated.",
  },
  "user:security:updated": {
    done: "Contact preferences updated.",
  },
  "user:verified": {
    done:
      "Account created. Complete your profile so that elite legal employers can find out more about you.",
  },
  "user:updated": {
    done: "User updated.",
  },
  "group:refresh": {
    done: "Group refreshed",
  },
}

export const CURRENT_YEAR = new Date().getFullYear()

export const UK_DEFAULT_COUNTRY = {
  id: "225",
  value: "United Kingdom",
}

export const OZ_DEFAULT_COUNTRY = {
  id: "13",
  value: "Australia",
}

export let YEARS_LIST = []
export let UNI_YEARS_LIST = []
export let UNI_GRADUATION_YEARS_LIST = []

for (let i = CURRENT_YEAR + 2; i >= CURRENT_YEAR - 50; i--) {
  YEARS_LIST.push({ id: i, value: i, label: i })
}

for (let i = CURRENT_YEAR + 4; i >= CURRENT_YEAR - 50; i--) {
  UNI_YEARS_LIST.push({ id: i, value: i, label: i })
}

for (let i = CURRENT_YEAR + 9; i >= CURRENT_YEAR - 50; i--) {
  UNI_GRADUATION_YEARS_LIST.push({ id: i, value: i, label: i })
}

export const DEGREE_TYPE = [
  { id: 1, value: "1", label: "Law" },
  { id: 2, value: "2", label: "Other" },
  {
    id: 3,
    value: "3",
    label: "STEM (Science, Technology, Engineering, Maths)",
  },
]

export const UNIVERSITY_GROUPS = {
  all: "Specific universities",
  go8: "Go 8 universities",
  russell: "Russell group",
  nonRussell: "Non Russell group",
  oxbridge: "Oxbridge group",
}

export const LABELS = {
  assessmentScore: "Assessment score",
  ks5CompletionYear: "KS5 completion year",
  ks4CompletionYear: "KS4 completion year",
  ks5Grade: "A levels (or equivalent)",
  ks4Grade: "GCSEs (or equivalent)",
  ks5SchoolPI: "KS5 PI",
  ks4SchoolPI: "KS4 PI",
  universityGraduationYear: "Graduation year",
  universityStartYear: "Uni start year",
  universityDegreeAverage: "Degree average",
  universityDegreePI: "Degree PI",
  degreeClass: "Degree class",
  universityYear1Average: "Year 1 Average",
  universityYear2Average: "Year 2  Average",
  universityYear3Average: "Year 3  Average",
  universityYear4Average: "Year 4  Average",
  universityModuleContractScore: "Contract score",
  universityModulePublicScore: "Public score",
  universityModuleTortScore: "Tort score",
  universityYear1PI: "Year 1 PI",
  universityYear2PI: "Year 2  PI",
  universityYear3PI: "Year 3  PI",
  universityYear4PI: "Year 4  PI",
  universityContractPI: "Contract PI",
  universityPublicPI: "Public PI",
  universityTortPI: "Tort PI",
  ks4SchoolPercentile: "KS4 school percentile",
  ks5SchoolPercentile: "KS5 school percentile",
}

export const LABELSFUNC = ukAPI => {
  return ukAPI
    ? {
        assessmentScore: "Assessment score",
        ks5CompletionYear: "KS5 completion year",
        ks4CompletionYear: "KS4 completion year",
        ks5Grade: "A levels (or equivalent)",
        ks4Grade: "GCSEs (or equivalent)",
        ks5SchoolPI: "KS5 PI",
        ks4SchoolPI: "KS4 PI",
        universityGraduationYear: "Graduation year",
        universityStartYear: "Uni start year",
        universityDegreeAverage: "Degree average",
        universityDegreePI: "Degree PI",
        degreeClass: "Degree class",
        universityYear1Average: "Year 1 Average",
        universityYear2Average: "Year 2  Average",
        universityYear3Average: "Year 3  Average",
        universityYear4Average: "Year 4  Average",
        universityModuleContractScore: "Contract score",
        universityModulePublicScore: "Public score",
        universityModuleTortScore: "Tort score",
        universityYear1PI: "Year 1 PI",
        universityYear2PI: "Year 2  PI",
        universityYear3PI: "Year 3  PI",
        universityYear4PI: "Year 4  PI",
        universityContractPI: "Contract PI",
        universityPublicPI: "Public PI",
        universityTortPI: "Tort PI",
        ks4SchoolPercentile: "KS4 school percentile",
        ks5SchoolPercentile: "KS5 school percentile",
      }
    : {
        ks5CompletionYear: "School completion year",
        ks5SchoolPI: "PI",
        ks4SchoolPI: "KS4 PI",
        ks5SchoolPercentile: "School percentile",
      }
}
/*
export const KS4_GRADES = {
  numOfAStar: "A"
}*/

export const FLAGS = [
  {
    id: 0,
    value: "0 flags",
  },
  {
    id: 1,
    value: "1+ flags",
  },
  {
    id: 2,
    value: "2+ flags",
  },
]

export const DEGREE_TYPES = [
  {
    id: 0,
    value: "Law degree",
  },
  {
    id: 1,
    value: "Non-Law degree",
  },
]

export const DATE_ADDED = [
  {
    id: "YESTERDAY",
    value: "Last day",
  },
  {
    id: "LAST_WEEK",
    value: "Last week",
  },
  {
    id: "LAST_MONTH",
    value: "Last month",
  },
  {
    id: "LAST_3MONTH",
    value: "Last 3 months",
  },
  {
    id: "LAST_6MONTH",
    value: "Last 6 months",
  },
  {
    id: "LAST_YEAR",
    value: "Last year",
  },
]
export const INCOMPLETE_PROFILE = [
  {
    id: "NO_KS5",
    value: "No KS5 data",
  },
  {
    id: "NO_UNI",
    value: "No Uni data",
  },
]

export const CANDIDATE_STATUS = [
  {
    id: "ACTIVE",
    value: "Submitted",
  },
  {
    id: "PENDING",
    value: "Not Submitted",
  },
  {
    id: "NOT_VERIFIED",
    value: "Unverified email",
  },
]

export const STEPS = [
  {
    id: "0",
    value: "Personal information",
  },
  {
    id: "1",
    value: "Secondary education",
  },
  {
    id: "2",
    value: "Sixth form education",
  },
  {
    id: "3",
    value: "University education",
  },
  {
    id: "4",
    value: "CRS information",
  },
  {
    id: "5",
    value: "T&Cs",
  },
  {
    id: "6",
    value: "Accepted T&Cs",
  },
]

export const SOCIAL_MOBILITY = ukAPI => {
  return ukAPI
    ? [
        {
          id: "firstGeneration",
          value: "First generation",
        },
        {
          id: "freeSchoolMeals",
          value: "Free school meals",
        },
        {
          id: "hasDisadvantagePostCode",
          value: "Disadvantaged postcode",
        },
        {
          id: "lowerSEBParentalOccupation",
          value: "Lower SEB parental occupation",
        },
        {
          id: "intermediateSEBParentalOccupation",
          value: "Intermediate SEB parental occupation",
        },
        {
          id: "refugeeSeeker",
          value: "Refugee/Asylum seeker",
        },
        {
          id: "carerParent",
          value: "Carer/parent",
        },
        {
          id: "timeSpentInCare",
          value: "Time spent in care",
        },
      ]
    : [
        {
          id: "aboriginal",
          value: "Aboriginal/Torres Strait Islander",
        },
        {
          id: "multipleSchools",
          value: "Attended multiple schools",
        },
        {
          id: "accessProgram",
          value: "Distance education course",
        },
        {
          id: "freeSchoolMeals",
          value: "Financial hardship",
        },
        {
          id: "firstGeneration",
          value: "First generation at university",
        },
        {
          id: "refugeeSeeker",
          value: "Refugee/Humanitarian Visa",
        },
        {
          id: "timeSpentInCare",
          value: "Time spent out-of-home care",
        },
      ]
}

export const TIERS = [
  {
    id: "BAR",
    value: "BAR",
    label: "Bar",
  },
  {
    id: "TIER_1",
    value: "TIER_1",
    label: "Tier 1",
  },
  {
    id: "TIER_2",
    value: "TIER_2",
    label: "Tier 2",
  },
  {
    id: "TIER_3",
    value: "TIER_3",
    label: "Tier 3",
  },
  {
    id: "TIER_4",
    value: "TIER_4",
    label: "Tier 4",
  },
  {
    id: "CANDID_ESSENTIALS",
    value: "CANDID_ESSENTIALS",
    label: "Candid Essentials",
  },
  {
    id: "CANDID_ESSENTIALS_PLUS",
    value: "CANDID_ESSENTIALS_PLUS",
    label: "Candid Essentials Plus",
  },
]

export const DATA_RETENTION = [
  {
    id: "6months",
    value: 0.5,
    label: "6 months",
  },
  {
    id: "1year",
    value: 1,
    label: "1 year",
  },
  {
    id: "2years",
    value: 2,
    label: "2 years",
  },
  {
    id: "3years",
    value: 3,
    label: "3 years",
  },
  {
    id: "4years",
    value: 4,
    label: "4 years",
  },
  {
    id: "5years",
    value: 5,
    label: "5 years",
  },
  {
    id: "6years",
    value: 6,
    label: "6 years",
  },
  {
    id: "7years",
    value: 7,
    label: "7 years",
  },
  {
    id: "8years",
    value: 8,
    label: "8 years",
  },
  {
    id: "9years",
    value: 9,
    label: "9 years",
  },
  {
    id: "10years",
    value: 10,
    label: "10 years",
  },
]

// Internet Explorer 6-11
export const isIE = /*@cc_on!@*/ false || !!document.documentMode

export const USERTYPES = [
  {
    id: "HIRING_MANAGER",
    label: "Standard",
    value: "HIRING_MANAGER",
  },
  {
    id: "HIRING_MANAGER_ADMIN",
    label: "Admin",
    value: "HIRING_MANAGER_ADMIN",
  },
]

export const generateOption = (id, value, label) => ({
  id: id,
  value: value || id,
  label: label || value || id,
})

export const generateOptionWithHint = (id, label, hint) => ({
  ...generateOption(id, id, label),
  hint,
})

export const STATUS_OPTIONS = [
  generateOption("Active", "ACTIVE", "Active"),
  generateOption("Archived", "ARCHIVED", "Archived"),
]

export const CANDIDX_ORG_STATUS_OPTIONS = [
  generateOption("Active", "ACTIVE", "Live"),
  generateOption("Potential", "POTENTIAL", "Potential"),
  generateOption("Dropped", "DROPPED", "Dropped"),
  generateOption("Other", "OTHER", "Other"),
]

export const PROGRAMME_STATE_OTPIONS = [
  generateOption("Active", "ACTIVE", "Live"),
  generateOption("Dropped", "DROPPED", "Dropped"),
  generateOption("Withdrew", "WITHDREW", "Withdrew"),
]

export const COMM_TYPES = [
  generateOption("EMAIL", "EMAIL", "Email"),
  generateOption("SMS", "SMS", "SMS"),
]

export const ATTENDANCE_OPTIONS = [
  generateOption("ATTENDED"),
  generateOption("NOSHOW", "NOSHOW", "NO SHOW"),
]

export const EXPORT_OPTIONS = [
  generateOption("Single", "Single", "Single"),
  generateOption("All", "All", "All"),
]

export const GROUP_EMAIL_OPTIONS = [
  generateOption("ALL", "Contact All"),
  generateOption("CONTACTED", "Contact New only"),
]

export const EVENT_EMAIL_OPTIONS = [
  generateOption("ALL", "Contact All"),
  generateOption("UNCONTACTED", "Contact New only"),
  generateOption("ATTENDED", "Contact Attended only"),
  generateOption("NOSHOW", "Contact No-show only"),
]

export const SQE_STATUS_OPTIONS = [
  generateOption("PASSED", "Passed"),
  generateOption("FAILED", "Failed"),
  generateOption("CURRENT", "Current Student"),
]

export const UNIVERSITY_STATUS_OPTIONS = [
  generateOption("UNCONFIRMED", "Place unconfirmed"),
  generateOption("CONFIRMED", "Place confirmed"),
  generateOption("CURRENT", "Current Student"),
  generateOption("PASSED", "Passed"),
  generateOption("COMMENDATION", "Commendation"),
  generateOption("DISTINCTION", "Distinction"),
]

export const DPLP_STATUS_OPTIONS = [
  generateOption("UNCONFIRMED", "Place unconfirmed"),
  generateOption("CONFIRMED", "Place confirmed"),
  generateOption("CURRENT", "Current Student"),
  generateOption("PASSED", "Passed"),
  generateOption("FAILED", "Failed"),
  generateOption("MERIT", "Merit"),
  generateOption("DISTINCTION", "Distinction"),
]

const generateTime = x => generateOption(x, x, hourLabel(x))

export const INTERVIEW_DURATION = [
  generateTime(15),
  generateTime(30),
  generateTime(45),
  generateTime(60),
  generateTime(75),
  generateTime(90),
  generateTime(105),
  generateTime(120),
  generateTime(180),
  generateTime(240),
  generateTime(300),
  generateTime(360),
  generateTime(420),
  generateTime(480),
  generateTime(540),
]

export const INTERVIEW_DURATION_LATERAL = [
  // generateTime(15),
  generateTime(30),
  generateTime(45),
  generateTime(60),
  generateTime(90),
  generateTime(120),
  generateTime(180),
  generateTime(240),
  generateTime(300),
  generateTime(360),
  generateTime(420),
  generateTime(480),
]

export const REMINDER_OPTIONS = [
  generateOption(1, 1, "1 day"),
  generateOption(2, 2, "2 days"),
  generateOption(3, 3, "3 days"),
  generateOption(4, 4, "4 days"),
  generateOption(5, 5, "5 days"),
]

export const NOTICE_OPTIONS = [
  generateOption(0, 0, "0 days"),
  generateOption(1, 1, "1 day"),
  generateOption(2, 2, "2 days"),
  generateOption(3, 3, "3 days"),
  generateOption(4, 4, "4 days"),
  generateOption(5, 5, "5 days"),
]

export const YES_OPTION = [{ label: "Yes", id: "Yes", value: "Yes" }]

export const FROM_YEAR = () => {
  return getCurrentRecruitmentYear() - 5
}

export const TO_YEAR = (offset = 5) => {
  return getCurrentRecruitmentYear() + offset + 1
}

export const REPORTING_GROUP_TYPE = [
  generateOption("SNAPSHOT", "SNAPSHOT", "Snapshot"),
  generateOption("ADVERSE", "ADVERSE", "Adverse impact"),
]

export const REPORTING_TYPE = [
  generateOption("CONTEXTUAL", "CONTEXTUAL", "Contextual report"),
  generateOption("DIVERSITY", "DIVERSITY", "Diversity report"),
  generateOption("EFFICIENCY", "EFFICIENCY", "Efficiency report"),
  generateOption("SCHOOL", "SCHOOL", "School report"),
  generateOption("UNIVERSITY", "UNIVERSITY", "University report"),
]

export const REPORTING_TYPE_LATERAL = [
  generateOption(
    "EFFICIENCY_LATERAL",
    "EFFICIENCY_LATERAL",
    "Efficiency report"
  ),
  generateOption("AGENCY_LATERAL", "AGENCY_LATERAL", "Agency report"),
  generateOption("DIVERSITY_LATERAL", "DIVERSITY_LATERAL", "Diversity report"),
  generateOption("MARKETING_LATERAL", "MARKETING_LATERAL", "Marketing report"),
  generateOption("REJECTION", "REJECTION", "Rejections report"),
]

export const REPORTING_TYPE_ADVERSE_LATERAL = [
  generateOption("DIVERSITY", "DIVERSITY", "Diversity report"),
]
export const REPORTING_SCOPE_TYPE_CONSTANTS = {
  ROLE_TEMPLATE: "ROLE_TEMPLATE",
  ROLE: "ROLE",
  DATE: "DATE",
  DEPARTMENT: "DEPARTMENT",
  ROLE_LEVEL: "ROLE_LEVEL",
}

export const REPORTING_SCOPE_TYPE_GRAD_SNAPSHOT = [
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_TEMPLATE,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_TEMPLATE,
    "Role templates"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    "Roles"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.DATE,
    REPORTING_SCOPE_TYPE_CONSTANTS.DATE,
    "Date range"
  ),
]
export const REPORTING_SCOPE_TYPE_ADVERSE = [
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_TEMPLATE,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_TEMPLATE,
    "Role templates"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    "Roles"
  ),
]

export const REPORTING_SCOPE_TYPE_LATERAL_SNAPSHOT = [
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_LEVEL,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE_LEVEL,
    "Role level"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    REPORTING_SCOPE_TYPE_CONSTANTS.ROLE,
    "Roles"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.DEPARTMENT,
    REPORTING_SCOPE_TYPE_CONSTANTS.DEPARTMENT,
    "Department"
  ),
  generateOption(
    REPORTING_SCOPE_TYPE_CONSTANTS.DATE,
    REPORTING_SCOPE_TYPE_CONSTANTS.DATE,
    "Date range"
  ),
]

export const DATE_RANGE_CONSTANTS = {
  THIRTY_DAYS: "THIRTY",
  SIXTY_DAYS: "SIXTY",
  NINETY_DAYS: "NINETY",
  YEAR: "YEAR",
  CUSTOM: "CUSTOM",
  ALL: "ALL_TIME",
}
export const DATE_RANGE_OPTIONS = [
  generateOption(
    DATE_RANGE_CONSTANTS.THIRTY_DAYS,
    DATE_RANGE_CONSTANTS.THIRTY_DAYS,
    "Last 30 days"
  ),
  generateOption(
    DATE_RANGE_CONSTANTS.SIXTY_DAYS,
    DATE_RANGE_CONSTANTS.SIXTY_DAYS,
    "Last 60 days"
  ),
  generateOption(
    DATE_RANGE_CONSTANTS.NINETY_DAYS,
    DATE_RANGE_CONSTANTS.NINETY_DAYS,
    "Last 90 days"
  ),
  generateOption(
    DATE_RANGE_CONSTANTS.YEAR,
    DATE_RANGE_CONSTANTS.YEAR,
    "Last 12 months"
  ),
  generateOption(
    DATE_RANGE_CONSTANTS.CUSTOM,
    DATE_RANGE_CONSTANTS.CUSTOM,
    "Custom range"
  ),
]

export const DATE_RANGE_ALL_OPTION = generateOption(
  DATE_RANGE_CONSTANTS.ALL,
  DATE_RANGE_CONSTANTS.ALL,
  "All time"
)

export const REPORTING_CALCULATION_TYPES = [
  generateOption("GRADE", "GRADE", "Grade adjusted"),
  generateOption("RAW", "RAW", "Raw"),
]

export const pad = i => (i < 10 ? `0${i}` : i)

export const TIMES = (include2359 = false) => {
  const result = []
  for (let i = 0; i < 24; i++) {
    result.push(generateOption(`${pad(i)}:00`))
  }
  if (include2359) {
    result.push(generateOption(`23:59`))
  }
  return result
}

export const TIMES15MINS = (
  include2359 = false,
  splitAtTime = false,
  exclude0000 = false
) => {
  let result = []
  for (let h = 0; h < 24; h++) {
    for (let m = 0; m < 60; m = m + 15) {
      result.push(generateOption(`${pad(h)}:${pad(m)}`))
    }
  }
  if (include2359) {
    result.push(generateOption(`23:59`))
  }

  if (splitAtTime) {
    const timeToSplitAt = result
      .map(function(e) {
        return e.value
      })
      .indexOf(splitAtTime)

    const firstHalf = result.slice(0, timeToSplitAt)
    const secondHalf = result.slice(timeToSplitAt)

    result = secondHalf.concat(firstHalf)
  }
  if (exclude0000) {
    result = result.filter(time => time.id !== "00:00")
  }
  return result
}

export const REJECTION_OPTIONS = [
  generateOption(
    "COMPETITIVE",
    "COMPETITIVE",
    "Application to highly competitive sectors"
  ),
  generateOption(
    "RESULT",
    "RESULT",
    "Grades, work experience, leadership and membership"
  ),
  generateOption("MISSING", "MISSING", "Insufficient information"),
  generateOption("SPELLING", "SPELLING", "Spelling and grammar errors"),
  generateOption(
    "SCOPE",
    "SCOPE",
    `Professional specialisms that Rare can't help with`
  ),
  generateOption("PERMIT", "PERMIT", "Work permit issues"),
  generateOption("UNRESPONSIVE", "UNRESPONSIVE", "Candidate unresponsive"),
]

export const ATS_REJECTION_OPTIONS = [
  generateOption(
    "RESULT",
    "RESULT",
    "Grades, work experience, leadership and membership"
  ),

  generateOption(
    "MISSING_RESEARCH",
    "MISSING_RESEARCH",
    "Insufficient research"
  ),
  generateOption("SPELLING", "SPELLING", "Spelling and grammar errors"),
  generateOption("PERMIT", "PERMIT", "Work permit issues"),
  generateOption("UNRESPONSIVE", "UNRESPONSIVE", "Candidate unresponsive"),
]

export const LATERAL_WITHDREW_OPTIONS = [
  generateOption("EXTERNAL_OFFER", "External Offer"),
  generateOption("INTERNAL_OFFER", "Internal Offer"),
  generateOption("OTHER", "Other"),
]

export const ASSIGNEES = [
  generateOption("ME", "ME", "Assigned to me"),
  generateOption("ALL", "ALL", "Assigned to everyone"),
]

export const AVAILABILITY = [
  generateOption("AVAILABLE", "AVAILABLE", "Available"),
  generateOption("HIRED", "HIRED", "Hired"),
  generateOption("UNAVAILABLE", "UNAVAILABLE", "Unavailable"),
  generateOption("DROPPED", "DROPPED", "Dropped"),
]

export const PERMIT_TYPE = [
  {
    id: "citizen",
    value: "UK citizen",
  },
  { id: "eu", value: "EU citizen with settled or pre-settled status" },
  {
    id: "study",
    value: "Post-study work/Graduate visa",
  },
  {
    id: "ilr",
    value: "Indefinite leave to remain",
  },
  {
    id: "other",
    value: "Other permit",
  },
  {
    id: "none",
    value: "I do not have a permit or the right to work in the UK",
  },
]

export const SCHOOL_TYPE = [
  {
    id: "comprehensive",
    value: "Comprehensive",
  },
  {
    id: "grammar",
    value: "Grammar",
  },
  {
    id: "independent",
    value: "Independent",
  },
  {
    id: "bursary",
    value: "Independent (scholarship/bursary)",
  },
]

export const CANDIDATE_TYPE = [
  {
    id: "school",
    value: "At school",
  },
  {
    id: "university",
    value: "At university",
  },
  {
    id: "graduated",
    value: "Graduated",
  },
]

export const CANDIDATE_AVAILABLE_STATUS = [
  {
    id: "available",
    value: "Available",
  },
  {
    id: "hired",
    value: "Hired",
  },
  {
    id: "unavailable",
    value: "Unavailable",
  },
  {
    id: "dropped",
    value: "Dropped",
  },
]

export const SESSION_TYPES = [
  generateOption(
    "ONE_TO_ONE_EXTERNAL",
    "ONE_TO_ONE_EXTERNAL",
    "1:1 coaching (external)"
  ),
  generateOption("ONE_TO_ONE_RARE", "ONE_TO_ONE_RARE", "1:1 coaching (Rare)"),
  generateOption("CALL", "CALL", "Catch-up call"),
  generateOption("MOCK", "MOCK", "Mock interview"),
  generateOption("ONLINE", "ONLINE", "Online application review"),
  generateOption("TEST", "TEST", "Test practice"),
  generateOption("WEBINAR", "WEBINAR", "Webinar"),
]

export const SESSION_DURATION = [
  generateOption(15, "15", "15 minutes"),
  generateOption(30, "30", "30 minutes"),
  generateOption(60, "60", "1 hour"),
  generateOption(120, "120", "2 hours"),
  generateOption(240, "240", "4 hours"),
]

export const ONE_TO_FIVE_SCORES = [
  generateOption(0, 0, "0"),
  generateOption(1, 1, "1"),
  generateOption(2, 2, "2"),
  generateOption(3, 3, "3"),
  generateOption(4, 4, "4"),
  generateOption(5, 5, "5"),
]

export const WATSON_GLASER_SCORE = "wgscore"

export const CAPPFINITY_SCORE = "cappfinityscore"
export const CAPPFINITY_SCORE_TYPE = "cappfinityscoretype"
export const CAPPFINITY_CANDIDATE_URL = "cappfinitycandidateurl"
export const CAPPFINITY_MANAGER_URL = "cappfinitymanagerurl"

export const SHL_SCORE = "shlscore"
export const SHL_SCORE_TYPE = "shlscoretype"
export const SHL_MANAGER_URL = "shlmanagerurl"

export const AMBERJACK_AUTOMATED = "amberjackautomated"
export const AMBERJACK_MANUAL = "amberjackmanual"
export const AMBERJACK_OVERALL = "amberjackoverall"

export const SOVA_STATUS = "sovaStatus"
export const SOVA_ASSESSMENT_CENTRE_RESPONSES_PREFIX = "SOVA-AC-"

export const SOVA_ONLINE_TEST_ID = "SOVA-OT"
export const SOVA_ASSESSMENT_TEST_ID = "SOVA-AC"

export const CUSTOM_ANSWERS_TO_IGNORE = [
  WATSON_GLASER_SCORE,
  CAPPFINITY_SCORE,
  CAPPFINITY_SCORE_TYPE,
  CAPPFINITY_CANDIDATE_URL,
  CAPPFINITY_MANAGER_URL,
  SHL_SCORE,
  SHL_SCORE_TYPE,
  SHL_MANAGER_URL,
  AMBERJACK_AUTOMATED,
  AMBERJACK_MANUAL,
  AMBERJACK_OVERALL,
  SOVA_STATUS,
]

export const SIXTH_AUTOCOMPLTED_YEAR = 2008
export const SECONDARY_AUTOCOMPLETED_YEAR = 2010
export const MINIMUMDIVERSITYEXPORT = 5

export const DEFAULT_ROWS = 10
export const DEFAULT_ROWS_MAX = 15
export const DEFAULT_MAX_LENGTH = 500
export const DEFAULT_MAX_CHARACTERS_INPUT_FIELD = 255
export const DEFAULT_MAX_CHARACTERS = 4096
export const MINIMUM_NUMBER_FOR_PAGINATION = 20
export const REJECTED_GROUP_STAGE = "REJECTED"
export const OFFER_ACCEPTED_GROUP_STAGE = "HIRED"
export const OFFER_MADE_GROUP_STAGE = "OFFERS"

export const VALUABLE_RESOURCES = [
  generateOption("All About Careers"),
  generateOption("All About Law"),
  generateOption("Black Solicitors Network"),
  generateOption("Bright Network"),
  generateOption("Chambers Asia Pacific"),
  generateOption("Chambers Hong Kong Student"),
  generateOption("Chambers Student Guide"),
  generateOption("CheekyLittleCareers"),
  generateOption("Debut"),
  generateOption("Diversity Solutions Event"),
  generateOption("Firm Brand Manager/Campus Ambassador"),
  generateOption("Firm contact"),
  generateOption("Firm event/open day"),
  generateOption("Firm school work experience programme"),
  generateOption("Firm social media"),
  generateOption("Firm virtual work experience programme"),
  generateOption("Firm website"),
  generateOption("Hong Kong Law Fair"),
  generateOption("Jobs.ac.uk"),
  generateOption("Law Society of Hong Kong"),
  generateOption("LawCareers.Net"),
  generateOption("Legal Cheek "),
  generateOption("Legal Cheek event"),
  generateOption("Legal Week"),
  generateOption("MyGwork"),
  generateOption("PRIME work experience"),
  generateOption("Rare Recruitment"),
  generateOption("Roll on Friday"),
  generateOption("Stonewall"),
  generateOption("Target Jobs"),
  generateOption("The Lawyer"),
  generateOption("The Lawyer Portal"),
  generateOption("The Legal 500 Asia Pacific"),
  generateOption("The Lex100"),
  generateOption("The Student Lawyer"),
  generateOption("The Times Top 100"),
  generateOption("The UK 300"),
  generateOption("University Careers Service"),
  generateOption("University event"),
  generateOption("University law fair"),
  generateOption("University society"),
  generateOption("Urban Lawyers"),
  generateOption("Vantage"),
  generateOption("Vantage event"),
  generateOption("None"),
  generateOption("Other"),
]

export const SUPPORTING_ORGANIZATIONS = [
  generateOption("AccesstoLaw"),
  generateOption("Aspiring Solicitors"),
  generateOption("Black Men in Law"),
  generateOption("Black Solicitors Network"),
  generateOption("Bright Network"),
  generateOption("Brokerage Citylink"),
  generateOption("City Solicitors Horizons"),
  generateOption("DiversCity event"),
  generateOption("Lawyer2Be"),
  generateOption("PRIME"),
  generateOption("Rare Recruitment"),
  generateOption("SEO"),
  generateOption("The Sutton Trust"),
  generateOption("upReach"),
  generateOption("Women in the City Afro-Caribbean Network"),
  generateOption("None"),
  generateOption("Other"),
]

export const VALUABLE_RESOURCES_NON_LAW = [
  generateOption("All About Careers"),
  generateOption("All About Law"),
  generateOption("None"),
  generateOption("Other"),
]

export const SUPPORTING_ORGANIZATIONS_NON_LAW = [
  generateOption("Bright Network"),
  generateOption("None"),
  generateOption("Other"),
]

export const NATIONALITIES = [
  generateOption("British"),
  generateOption("Afghan"),
  generateOption("Albanian"),
  generateOption("Algerian"),
  generateOption("American"),
  generateOption("Andorran"),
  generateOption("Angolan"),
  generateOption("Anguillan"),
  generateOption("Argentine"),
  generateOption("Armenian"),
  generateOption("Australian"),
  generateOption("Austrian"),
  generateOption("Azerbaijani"),
  generateOption("Bahamian"),
  generateOption("Bahraini"),
  generateOption("Bangladeshi"),
  generateOption("Barbadian"),
  generateOption("Belarusian"),
  generateOption("Belgian"),
  generateOption("Belizean"),
  generateOption("Beninese"),
  generateOption("Bermudian"),
  generateOption("Bhutanese"),
  generateOption("Bolivian"),
  generateOption("Botswanan"),
  generateOption("Brazilian"),
  generateOption("British Virgin Islander"),
  generateOption("Bruneian"),
  generateOption("Bulgarian"),
  generateOption("Burkinan"),
  generateOption("Burmese"),
  generateOption("Burundian"),
  generateOption("Cambodian"),
  generateOption("Cameroonian"),
  generateOption("Canadian"),
  generateOption("Cape Verdean"),
  generateOption("Cayman Islander"),
  generateOption("Central African"),
  generateOption("Chadian"),
  generateOption("Chilean"),
  generateOption("Chinese"),
  generateOption("Chinese (HKSAR)"),
  generateOption("Citizen of Antigua and Barbuda"),
  generateOption("Citizen of Bosnia and Herzegovina"),
  generateOption("Citizen of Guinea-Bissau"),
  generateOption("Citizen of Kiribati"),
  generateOption("Citizen of Seychelles"),
  generateOption("Citizen of the Dominican Republic"),
  generateOption("Citizen of Vanuatu "),
  generateOption("Colombian"),
  generateOption("Comoran"),
  generateOption("Congolese (Congo)"),
  generateOption("Congolese (DRC)"),
  generateOption("Cook Islander"),
  generateOption("Costa Rican"),
  generateOption("Croatian"),
  generateOption("Cuban"),
  generateOption("Cymraes"),
  generateOption("Cymro"),
  generateOption("Cypriot"),
  generateOption("Czech"),
  generateOption("Danish"),
  generateOption("Djiboutian"),
  generateOption("Dominican"),
  generateOption("Dutch"),
  generateOption("East Timorese"),
  generateOption("Ecuadorean"),
  generateOption("Egyptian"),
  generateOption("Emirati"),
  generateOption("English"),
  generateOption("Equatorial Guinean"),
  generateOption("Eritrean"),
  generateOption("Estonian"),
  generateOption("Ethiopian"),
  generateOption("Faroese"),
  generateOption("Fijian"),
  generateOption("Filipino"),
  generateOption("Finnish"),
  generateOption("French"),
  generateOption("Gabonese"),
  generateOption("Gambian"),
  generateOption("Georgian"),
  generateOption("German"),
  generateOption("Ghanaian"),
  generateOption("Gibraltarian"),
  generateOption("Greek"),
  generateOption("Greenlandic"),
  generateOption("Grenadian"),
  generateOption("Guamanian"),
  generateOption("Guatemalan"),
  generateOption("Guinean"),
  generateOption("Guyanese"),
  generateOption("Haitian"),
  generateOption("Honduran"),
  generateOption("Hong Konger"),
  generateOption("Hungarian"),
  generateOption("Icelandic"),
  generateOption("Indian"),
  generateOption("Indonesian"),
  generateOption("Iranian"),
  generateOption("Iraqi"),
  generateOption("Irish"),
  generateOption("Israeli"),
  generateOption("Italian"),
  generateOption("Ivorian"),
  generateOption("Jamaican"),
  generateOption("Japanese"),
  generateOption("Jordanian"),
  generateOption("Kazakh"),
  generateOption("Kenyan"),
  generateOption("Kittitian"),
  generateOption("Kosovan"),
  generateOption("Kuwaiti"),
  generateOption("Kyrgyz"),
  generateOption("Lao"),
  generateOption("Latvian"),
  generateOption("Lebanese"),
  generateOption("Liberian"),
  generateOption("Libyan"),
  generateOption("Liechtenstein citizen"),
  generateOption("Lithuanian"),
  generateOption("Luxembourger"),
  generateOption("Macanese"),
  generateOption("Macedonian"),
  generateOption("Malagasy"),
  generateOption("Malawian"),
  generateOption("Malaysian"),
  generateOption("Maldivian"),
  generateOption("Malian"),
  generateOption("Maltese"),
  generateOption("Marshallese"),
  generateOption("Martiniquais"),
  generateOption("Mauritanian"),
  generateOption("Mauritian"),
  generateOption("Mexican"),
  generateOption("Micronesian"),
  generateOption("Moldovan"),
  generateOption("Monegasque"),
  generateOption("Mongolian"),
  generateOption("Montenegrin"),
  generateOption("Montserratian"),
  generateOption("Moroccan"),
  generateOption("Mosotho"),
  generateOption("Mozambican"),
  generateOption("Namibian"),
  generateOption("Nauruan"),
  generateOption("Nepalese"),
  generateOption("New Zealander"),
  generateOption("Nicaraguan"),
  generateOption("Nigerian"),
  generateOption("Nigerien"),
  generateOption("Niuean"),
  generateOption("North Korean"),
  generateOption("Northern Irish"),
  generateOption("Norwegian"),
  generateOption("Omani"),
  generateOption("Pakistani"),
  generateOption("Palauan"),
  generateOption("Palestinian"),
  generateOption("Panamanian"),
  generateOption("Papua New Guinean"),
  generateOption("Paraguayan"),
  generateOption("Peruvian"),
  generateOption("Pitcairn Islander"),
  generateOption("Polish"),
  generateOption("Portuguese"),
  generateOption("Prydeinig"),
  generateOption("Puerto Rican"),
  generateOption("Qatari"),
  generateOption("Romanian"),
  generateOption("Russian"),
  generateOption("Rwandan"),
  generateOption("Salvadorean"),
  generateOption("Sammarinese"),
  generateOption("Samoan"),
  generateOption("Sao Tomean"),
  generateOption("Saudi Arabian"),
  generateOption("Scottish"),
  generateOption("Senegalese"),
  generateOption("Serbian"),
  generateOption("Sierra Leonean"),
  generateOption("Singaporean"),
  generateOption("Slovak"),
  generateOption("Slovenian"),
  generateOption("Solomon Islander"),
  generateOption("Somali"),
  generateOption("South African"),
  generateOption("South Korean"),
  generateOption("South Sudanese"),
  generateOption("Spanish"),
  generateOption("Sri Lankan"),
  generateOption("St Helenian"),
  generateOption("St Lucian"),
  generateOption("Stateless"),
  generateOption("Sudanese"),
  generateOption("Surinamese"),
  generateOption("Swazi"),
  generateOption("Swedish"),
  generateOption("Swiss"),
  generateOption("Syrian"),
  generateOption("Taiwanese"),
  generateOption("Tajik"),
  generateOption("Tanzanian"),
  generateOption("Thai"),
  generateOption("Togolese"),
  generateOption("Tongan"),
  generateOption("Trinidadian"),
  generateOption("Tristanian"),
  generateOption("Tunisian"),
  generateOption("Turkish"),
  generateOption("Turkmen"),
  generateOption("Turks and Caicos Islander"),
  generateOption("Tuvaluan"),
  generateOption("Ugandan"),
  generateOption("Ukrainian"),
  generateOption("Uruguayan"),
  generateOption("Uzbek"),
  generateOption("Vatican citizen"),
  generateOption("Venezuelan"),
  generateOption("Vietnamese"),
  generateOption("Vincentian"),
  generateOption("Wallisian"),
  generateOption("Welsh"),
  generateOption("Yemeni"),
  generateOption("Zambian"),
  generateOption("Zimbabwean"),
]

export const BASE_COUNTRIES = [
  generateOption("UK"),
  generateOption("OZ"),
  generateOption("HK"),
]

export const PARENTAL_OCCUPATIONS = [
  generateOptionWithHint(
    "P1",
    "Modern professional & traditional professional occupations",
    "e.g. teacher, nurse, physiotherapist, social worker, musician, police officer (sergeant or above), software designer, accountant, solicitor, medical practitioner, scientist, civil / mechanical engineer."
  ),
  generateOptionWithHint(
    "P2",
    "Senior, middle or junior managers or administrators",
    "e.g. finance manager, chief executive, large business owner, office manager, retail manager, bank manager, restaurant manager, warehouse manager."
  ),
  generateOptionWithHint(
    "I1",
    "Clerical and intermediate occupations",
    "e.g. secretary, personal assistant, call centre agent, clerical worker, nursery nurse."
  ),
  generateOptionWithHint(
    "I2",
    "Small business owners who employed less than 25 people",
    "e.g. corner shop owners, small plumbing companies, retail shop owner, single restaurant or cafe owner, taxi owner, garage owner"
  ),
  generateOptionWithHint(
    "L1",
    "Technical and craft occupations",
    "such as: motor mechanic, plumber, printer, electrician, gardener, train driver."
  ),
  generateOptionWithHint(
    "L2",
    "Routine, semi-routine manual and service occupations",
    "e.g. postal worker, machine operative, security guard, caretaker, farm worker, catering assistant, sales assistant, HGV driver, cleaner, porter, packer, labourer, waiter/waitress, bar staff."
  ),
  generateOptionWithHint(
    "L3",
    "Long-term unemployed",
    "e.g. claimed Jobseeker’s Allowance or earlier unemployment benefit for more than a year"
  ),
  generateOptionWithHint(
    "X1",
    "Other",
    "e.g. retired, this question does not apply to me, I don’t know"
  ),
  generateOptionWithHint("X2", "I prefer not to say", ""),
]

export const NI_QUESTIONS = [
  generateOption("Protestant Community"),
  generateOption("Roman Catholic Community"),
  generateOption("Neither the Protestant or the Roman Catholic Communities."),
]

export const ATS = "ATS"
export const VANTAGE = "VANTAGE"
export const CRSAO = "CRSAO"

export const PRODUCTS = [
  generateOption(ATS),
  generateOption(VANTAGE),
  generateOption(CRSAO),
]

export const PERMIT_TYPE_LATERAL = [
  generateOption("Citizen"),
  generateOption("Passport allowing work legally"),
  generateOption("Visa allowing work full time without restriction"),
  generateOption("Visa attached to current employer"),
  generateOption("Sponsorship required"),
  generateOption("No right to work in this jurisdiction"),
]

export const NOTICE_PERIODS = [
  generateOption("No notice period"),
  generateOption("Less than 1 month"),
  generateOption("1 month"),
  generateOption("2 months"),
  generateOption("3 months"),
  generateOption("4 months"),
  generateOption("5 months"),
  generateOption("6 months"),
  generateOption("6+ months"),
]

export const UK_COUNTRIES = [
  generateOption("England"),
  generateOption("Scotland"),
  generateOption("Wales"),
  generateOption("Northern Ireland"),
]

export const HEARD_LATERAL = [
  generateOption("Agency"),
  generateOption("Career Legal.com"),
  generateOption("CGIUKI (formerly) ICSA"),
  generateOption("CILEX website"),
  generateOption("CLC Website"),
  generateOption("Event"),
  generateOption("Facebook"),
  generateOption("Firm intranet"),
  generateOption("Firm/ 'Our' website"),
  generateOption("Google search"),
  generateOption("Google+"),
  generateOption("Indeed"),
  generateOption("Job board"),
  generateOption("Job Centre Plus"),
  generateOption("Law Gazette"),
  generateOption("Legal support network"),
  generateOption("Legal Week"),
  generateOption("LinkedIn"),
  generateOption("Magazines and trade publications"),
  generateOption("Monster"),
  generateOption("Newspapers/Press"),
  generateOption("Other"),
  generateOption("Professional associations"),
  generateOption("Professionals in Law"),
  generateOption("Reed"),
  generateOption("Referral from employee"),
  generateOption("Referral from other"),
  generateOption("Roll on Friday"),
  generateOption("S1 jobs"),
  generateOption("Scottish Legal News"),
  generateOption("Scottish Paralegal Association"),
  generateOption("Simply Law"),
  generateOption("The Caterer"),
  generateOption("The Lawyer"),
  generateOption("TotalJobs"),
  generateOption("Totally Legal"),
  generateOption("Twitter"),
  generateOption("University/ University Job Board/ University Recruiting"),
]

export const HEARD_LATERAL_EXTRA = {
  Event: "Please specify which event and when",
  Other: "Please specify",
  "University/ University Job Board/ University Recruiting": "Please specify",
}

export const DEPARTMENT_TYPE = [
  generateOption("LAW", "Qualified Lawyers"),
  generateOption("BUSINESS", "Business Services"),
]

export const APP_SOURCES = [
  generateOption("AGENCY", "Agency"),
  generateOption("DIRECT", "Direct"),
]

export const SCREENING_STAGES = [STAGES.APPLIED, STAGES.FOR_REVIEW]
export const INTERVIEW_STAGES = [
  STAGES.AT_INTERVIEW,
  STAGES.AT_PHONE_INTERVIEW,
  STAGES.AT_1ST_ROUND_INTERVIEW,
  STAGES.AT_2ND_ROUND_INTERVIEW,
  STAGES.AT_ASSESSMENT_CENTRE,
  STAGES.AT_FINAL_ROUND,
]

export const INTERVIEW_TYPE = {
  AMBERJACK: "AMBERJACK",
  CAPPFINITY: "CAPPFINITY",
  WATSON_GLASER: "WATSON_GLASER",
  SHL: "SHL",
  SOVA: "SOVA",
  SCHEDULER: "SCHEDULER",
  SCREENING: "SCREENING",
  SCHEDULED: "SCHEDULED",
}

const configurations = [
  {
    id: INTERVIEW_TYPE.WATSON_GLASER,
    config: "watsonUser",
  },
  {
    id: INTERVIEW_TYPE.CAPPFINITY,
    config: "cappfinityUser",
  },
  {
    id: INTERVIEW_TYPE.AMBERJACK,
    config: "amberjackUser",
  },
  {
    id: INTERVIEW_TYPE.SHL,
    config: "shlUser",
  },
  {
    id: INTERVIEW_TYPE.SOVA,
    config: "sovaUser",
  },
]

export const getAssessmentTypes = (organization, all) => {
  const configuration = getFormConfigurationFromOrganization(organization)
  let data = [
    {
      id: INTERVIEW_TYPE.SCHEDULER,
      label: "Candidate scheduler",
      value: "Candidate scheduler",
      stages: INTERVIEW_STAGES,
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.WATSON_GLASER,
      label: "Watson Glaser",
      value: "Watson Glaser",
      stages: [STAGES.AT_WATSON_GLASER_TEST],
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.CAPPFINITY,
      label: "Cappfinity",
      value: "Cappfinity",
      stages: [STAGES.AT_ONLINE_TEST],
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.SHL,
      label: "SHL",
      value: "SHL",
      stages: [STAGES.AT_ONLINE_TEST],
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.AMBERJACK,
      label: "Amberjack",
      value: "Amberjack",
      stages: [STAGES.AT_VIDEO_INTERVIEW, STAGES.AT_ONLINE_TEST],
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.SOVA,
      label: "SOVA",
      value: "SOVA",
      stages: [STAGES.AT_ASSESSMENT_CENTRE, STAGES.AT_ONLINE_TEST],
      lateral: false,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [],
    },
    {
      id: INTERVIEW_TYPE.SCREENING,
      label: "Application screen",
      value: "Application screen",
      stages: SCREENING_STAGES,
      lateral: isHREnabled(configuration, "screening") ? undefined : true,
      excludeOnlyForInterview: [],
      includeOnlyForInterview: [CODESTAGES.IN_PROGRESS, CODESTAGES.COMPLETED],
    },
    {
      id: INTERVIEW_TYPE.SCHEDULED,
      label: "HR scheduler",
      value: "HR scheduler",
      stages: INTERVIEW_STAGES,
      lateral: true,
      excludeOnlyForInterview: [CODESTAGES.INVITED],
      includeOnlyForInterview: [CODESTAGES.INVITED],
    },
  ]
  if (!all) {
    configurations.forEach(config => {
      if (
        !configuration ||
        !configuration.text ||
        !configuration.text[config.config]
      ) {
        data = data.filter(x => x.id !== config.id)
      }
    })
  }
  return isLateralMode()
    ? data.filter(x => x.lateral === true || x.lateral === undefined)
    : all
    ? data
    : data.filter(x => x.lateral === false || x.lateral === undefined)
}

export const DISABILITY_OPTIONS = [
  generateOption("Dyslexia"),
  generateOption(
    "Energy limiting condition (for example chronic fatigue or fibromyalgia)"
  ),
  generateOption("Hearing"),
  generateOption("Learning disability"),
  generateOption(
    "Long-term condition (for example diabetes, multiple sclerosis, heart conditions, epilepsy, or chronic pain)"
  ),
  generateOption("Mental health condition"),
  generateOption("Musculoskeletal (for example, back or neck and shoulder)"),
  generateOption("Physical or mobility"),
  generateOption("Speech"),
  generateOption("Visual"),
  generateOption("Work related stress"),
  generateOption("Other 'neurodiverse' for example dyscalculia or autism"),
  generateOption("Other (specify)"),
  generateOption("Prefer not to say"),
]

export const LINK_EXPIRY = [
  generateOption(1, "1 day"),
  generateOption(3, "3 days"),
  generateOption(5, "5 days"),
  generateOption(7, "7 days"),
  generateOption(10, "10 days"),
  generateOption(14, "14 days"),
  generateOption(21, "21 days"),
  generateOption(28, "28 days"),
  generateOption(200, "200 days"),
]

export const FIRST_UNIVERSITY_OPTIONS = [
  generateOption("Yes"),
  generateOption("No"),
  generateOption("I did not attend university"),
  generateOption("Prefer not to say"),
]

export const FREE_SCHOOL_OPTIONS = [
  generateOption("Yes"),
  generateOption("No"),
  generateOption(
    "Not applicable (finished school before 1980 or went to school overseas)"
  ),
  generateOption("Prefer not to say"),
]

const a_levels = [
  "Accounting",
  "Ancient History",
  "Anthropology",
  "Applied ICT",
  "Arabic",
  "Archaeology",
  "Architecture",
  "Art and Design",
  "Bengali",
  "Bengali (Listening, Reading, Writing)",
  "Biblical Hebrew",
  "Biology",
  "Biology A",
  "Biology A (Salters-Nuffield)",
  "Biology B",
  "Biology B (Advancing Biology)",
  "Built Environment",
  "Business",
  "Business Studies",
  "Chemistry",
  "Chemistry A",
  "Chemistry B (Salters)",
  "Chinese",
  "Classical Civilisation",
  "Classical Greek",
  "Classical Studies",
  "Communication and Culture",
  "Computer Science",
  "Computing",
  "Criminology",
  "Dance",
  "Design and Technology",
  "Design and Technology: Fashion and Textiles",
  "Design and Technology: Product Design",
  "Design and Textiles",
  "Digital Media and Design",
  "Digital Technology",
  "Divinity",
  "Drama",
  "Drama and Theatre",
  "Dutch",
  "Dutch",
  "Economics",
  "Economics A",
  "Economics B",
  "Electronics",
  "Engineering",
  "English Language",
  "English Language and Literature",
  "English Language and Literature (EMC)",
  "English Literature",
  "English Literature A",
  "English Literature B",
  "Environmental Science",
  "Environmental Technology",
  "Fashion and Textiles",
  "Film Studies",
  "Food Studies",
  "Food Technology",
  "French",
  "Further Mathematics",
  "General Studies",
  "General Studies",
  "Geography",
  "Geology",
  "German",
  "Global Development",
  "Global Perspectives and Research",
  "Government and Politics",
  "Greek",
  "Gujarati",
  "Health and Social Care",
  "Health and Social Care, and Childcare",
  "Hindi",
  "Hinduism",
  "History",
  "History A",
  "History of Art",
  "Home Economics",
  "Human Biology",
  "Humanities",
  "Information and Communication Technology (ICT)",
  "Information Technology",
  "International Relations",
  "Irish",
  "Islamic Studies",
  "Italian",
  "Japanese",
  "Journalism in the Media and Communications Industry",
  "Latin",
  "Law",
  "Leisure Studies",
  "Life and Health Sciences",
  "Mathematics",
  "Mathematics A",
  "Mathematics A, Further",
  "Mathematics B (MEI)",
  "Mathematics B (MEI), Further",
  "Media Studies",
  "Modern Hebrew (Listening, Reading, Writing)",
  "Moving Image Arts",
  "Music",
  "Music Technology",
  "Nutrition and Food Science",
  "Panjabi (Listening, Reading, Writing)",
  "Performance Studies",
  "Performing Arts",
  "Persian",
  "Philosophy",
  "Photography",
  "Physical Education",
  "Physical Science",
  "Physics",
  "Physics A",
  "Physics B (Advancing Physics)",
  "Polish (Listening, Reading, Writing)",
  "Politics",
  "Portuguese",
  "Product Design",
  "Professional Business Services",
  "Psychology",
  "Pure Mathematics",
  "Quantitative Methods",
  "Religious Studies",
  "Russian",
  "Science in Society",
  "Sociology",
  "Software Systems Development",
  "Spanish",
  "Sports Science and the Active Leisure Industry",
  "Statistics",
  "Systems and Control Technology",
  "Tamil",
  "Technology and Design",
  "Technology and Design",
  "Telugu",
  "Thinking Skills",
  "Travel and Tourism",
  "Travel and Tourism",
  "Turkish",
  "Urdu",
  "Welsh Language",
  "Welsh Second Language",
]

export const A_LEVELS = a_levels.map(x => generateOption(x))

const gsces = [
  "Agriculture and Land Use",
  "Ancient History",
  "Applied Science (Double Award)",
  "Applied Science (Single Award)",
  "Arabic",
  "Art and Design",
  "Astronomy",
  "Bengali",
  "Biblical Hebrew",
  "Biology",
  "Biology A",
  "Biology B",
  "Built Environment",
  "Business",
  "Business and Communication Systems",
  "Business Studies",
  "Chemistry",
  "Chemistry A",
  "Chemistry B",
  "Chinese (Cantonese)",
  "Chinese (Mandarin)",
  "Citizenship",
  "Citizenship Studies",
  "Classical Civilisation",
  "Classical Greek",
  "Combined Science",
  "Combined Science: Synergy",
  "Combined Science: Trilogy",
  "Computer Science",
  "Construction and the Built Environment",
  "Contemporary Crafts",
  "Dance",
  "Design and Technology",
  "Digital Technology",
  "Drama",
  "Dutch",
  "Economics",
  "Electronics",
  "Engineering",
  "Engineering and Manufacturing",
  "English Language",
  "English Literature",
  "Film Studies",
  "Food and Nutrition",
  "Food Preparation and Nutrition",
  "French",
  "Further Mathematics",
  "Gaeilge",
  "General Studies",
  "Geography",
  "Geography A",
  "Geography A (Geographical Themes)",
  "Geography B",
  "Geography B (Geography for Enquiring Minds)",
  "Geology",
  "German",
  "Government and Politics",
  "Graphics",
  "Greek",
  "Gujarati",
  "Health and Social Care",
  "Health and Social Care and Childcare",
  "History",
  "History A (Explaining the Modern World)",
  "History B (Schools History Project)",
  "Home Economics",
  "Home Economics: Child Development",
  "Home Economics: Food and Nutrition",
  "Hospitality",
  "Information and Communication Technology (ICT)",
  "Irish",
  "Italian",
  "Japanese",
  "Journalism in the Media and Communications Industry",
  "Latin",
  "Learning for Life and Work",
  "Leisure, Travel and Tourism",
  "Mathematics",
  "Mathematics - Numeracy",
  "Media Studies",
  "Modern Greek",
  "Modern Hebrew",
  "Motor Vehicle and Road User Studies",
  "Moving Image Arts",
  "Music",
  "Persian",
  "Photography",
  "Physical Education",
  "Physical Education (Short Course)",
  "Physics",
  "Physics A",
  "Physics B",
  "Polish",
  "Portuguese",
  "Psychology",
  "Punjabi",
  "Religious Studies",
  "Religious Studies A",
  "Religious Studies B",
  "Religious Studies: Short course",
  "Russian",
  "Science A, Combined",
  "Science B, Combined",
  "Science Double Award",
  "Science Single Award",
  "Sociology",
  "Spanish",
  "Statistics",
  "Technology and Design",
  "Turkish",
  "Urdu",
  "Welsh (Second Language)",
  "Welsh Language (First language)",
  "Welsh Literature (First language)",
]

export const GCSE_DOUBLE_AWARD_COURSES = [
  "Applied Science (Double Award)",
  "Science Double Award",
  "Combined Science",
  "Combined Science: Synergy",
  "Combined Science: Trilogy",
]
export const GCSES = gsces.map(x => generateOption(x))

export const MAX_INTERVIEWS_PER_SLOT = 100

export const BOOLEAN_TYPE = "BOOLEAN"
export const PERCENT_TYPE = "PERCENT"

export const FIELD_TYPE = [
  generateOption(BOOLEAN_TYPE),
  generateOption("STRING"),
  generateOption("HTML"),
  generateOption("ARRAY"),
]

export const METRIC_TYPE = [
  generateOption(PERCENT_TYPE),
  generateOption("NUMBER"),
  generateOption("TEXT"),
  generateOption(BOOLEAN_TYPE),
]

export const QUESTION_TYPE = [
  generateOption("date", "DATE"),
  generateOption("info", "MESSAGE"),
  generateOption("text", "TEXT"),
  generateOption("textarea", "TEXTAREA"),
  generateOption("exptextarea", "EXPORTABLE TEXTAREA"),
  generateOption("boolean", "TOGGLE"),
  generateOption("cboolean", "CHECKBOX"),
  generateOption("multiselect", "CHECKBOX LIST"),
  generateOption("dropdown", "DROPDOWN"),
  generateOption("prefer", "PREFER NOT TO SAY"),
  generateOption("option", "SINGLE OPTION"),
  generateOption("aoptions", "OPTIONS LIST"),
  generateOption("options", "CUSTOM PREFER NOT TO SAY"),
  generateOption("autocomplete", "AUTO COMPLETE"),
]

export const INPUT_QUESTION_TYPE = [
  generateOption("text", "TEXT"),
  generateOption("number", "NUMBER"),
]

export const LOGIC_TYPE = [
  generateOption("eq", "IS TRUE"),
  generateOption("in", "CONTAINS VALUE"),
  generateOption("atrue", "LIST HAS A TRUE VALUE"),
  generateOption("afalse", "LIST HAS A FALSE VALUE"),
]

export const WORK_EXPERIENCE_TYPE = [
  generateOption("Open access virtual internship"),
  generateOption("Exclusive virtual internship"),
  generateOption("In person internship"),
  generateOption("Informal work shadowing"),
  generateOption("Volunteering"),
  generateOption("Part-time role"),
  generateOption("Full-time role"),
  generateOption("Insight Event / Open Day"),
  generateOption("Other"),
]

export const GROUP_COMMENT_MAXIMUM_LENGTH = 100
export const MAXIMUM_CANDIDATES_CSV = 1000
export const OTHER_REJECTION_REASONS_SPLITTER = "Other: "
export const PRONOUNS_LIST = [
  { value: "He/him", id: "He/him" },
  { value: "She/her", id: "She/her" },
  { value: "They/them", id: "They/them" },
  { value: "Custom", id: "Custom", custom: true },
]

export const MAX_SMS_CHARACTERS = isInternalMode() ? 480 : 320

export const CUSTOM_STRING_SPLITTER = "---"

export const PI_HIGHLIGHT_VALUE = 30

export const SECTOR_LAW_ID = "3"
export const SECTOR_LAW_SCOTLAND_ID = "20"
export const SECTOR_CITY_LAW_ID = "8"
export const SECTOR_CHAMBERS_ID = "9"

export const NO_CRAWL_URLS = [
  `burnesspaull-lateral.app.rstaging.co.uk`,
  `burnesspaull-ats.app.rstaging.co.uk`,
  `burnesspaull-lateral.app.rdev.co.uk`,
  `burnesspaull-ats.app.rdev.co.uk`,
  `burnesspaull.appx.candidats.io`,
  `burnesspaull.app.candidats.io`,
]

export const ETHNICITY_OTHER_ID = "12"
export const MIXED_OTHER_ETHNICITY_IDS = ["2", "6", "15", ETHNICITY_OTHER_ID]
export const MIXED_OTHER_ETHNICITY_ID = "8"

const MALE = "MALE"
const BAME = "BAME"
const ASIAN = "ASIAN"
const BLACK = "BLACK"
const FLAGS1 = "FLAGS1"
const PI = "PI"

export const VANTAGE_FIND = {
  MALE: MALE,
  BLACK: BLACK,
  ASIAN: ASIAN,
  BAME: BAME,
  FLAGS1: FLAGS1,
  PI: PI,
}

export const FILTER_GENDER_MALE_ID = "1"
export const FILTER_ETHNICITY_BLACK = 1
export const FILTER_ETHNICITY_BAME = 2
export const FILTER_SPECIFIC_BANGLADESHI_PAKISTANI_IDS = ["3", "17"]
export const FILTER_GENDER_FLAGS_ID = "1"
export const FILTER_INTERESTS_SOLICITOR_ID = "1"
export const FILTER_INTERESTS_EXECUTIVE_ID = "3"
export const FILTER_INTERESTS_APPRENTICE_ID = "5"

export const ROLE_TYPE_FIRST_YEAR = 3
export const ROLE_TYPE_APPRENTICE = 4
export const ROLE_TYPE_SCHOOL_PROGRAMME = 6

const UK = "UK"
const OZ = "OZ"
const GCC = "GCC"
const HK = "HK"

export const COUNTRY_BASE = {
  UK: UK,
  OZ: OZ,
  GCC: GCC,
  HK: HK,
}

export const UK_COUNTRY_OTHER_ID = 5
export const OZ_COUNTRY_OTHER_ID = 14
export const UK_COUNTRY_OTHER_ID_THAT_USES_API = [1, 2, 3, 4]

export const DAYS_PER_WEEK = [
  generateOption(1, "1 day per week"),
  generateOption(2, "2 days per week"),
  generateOption(3, "3 days per week"),
  generateOption(4, "4 days per week"),
  generateOption(5, "5 days per week"),
  generateOption(6, "6 days per week"),
  generateOption(7, "7 days per week"),
]

export const ENTRANCE_SCORE_TYPE_NOT_AUSTRALIA =
  "I did not/will not attend an Australian university"

export const OZ_ENTRANCE_SCORE_TYPE = [
  generateOption("ATAR"),
  generateOption("ENTER"),
  generateOption("OP"),
  generateOption("TER"),
  generateOption("UAI"),
  generateOption(ENTRANCE_SCORE_TYPE_NOT_AUSTRALIA),
  generateOption("OTHER", "Other (Please specify type and score)"),
]

export const WAM_RANGE = [
  generateOption("Under 50"),
  generateOption("50 - 54.99"),
  generateOption("55 - 59.99"),
  generateOption("60 - 64.99"),
  generateOption("65 - 69.99"),
  generateOption("70 - 74.99"),
  generateOption("75 - 79.99"),
  generateOption("80 - 84.99"),
  generateOption("85+"),
]

export const UNDERGRADUATE = "UNDERGRADUATE"
export const GRADUATE = "GRADUATE"
export const PHD = "PHD"
export const GDL = "GDL"
export const LPC = "LPC"
export const DPLP = "DPLP"
export const SQE1 = "SQE1"
export const SQE2 = "SQE2"
export const JD = "JD"
export const PCLL = "PCLL"

export const UNIVERSITY_TYPE = {
  UNDERGRADUATE,
  GRADUATE,
  PHD,
  GDL,
  LPC,
  DPLP,
  SQE1,
  SQE2,
  JD,
  PCLL,
}

export const MIN_AMOUNT_TO_TYPE = 2
export const DEBOUNCE_DELAY = 250

export const LIVE_ROLES_SORT = "LIVE_ROLES"
export const SETTINGS_ROLES_SORT = "SETTINGS_ROLES"
export const SETTINGS_TEMPLATES_SORT = "SETTINGS_TEMPLATES"
export const SETTINGS_COMMTEMPLATES_SORT = "SETTINGS_COMM_TEMPLATES"
export const GROUPS_SORT = "GROUPS_SORT"
export const EVENTS_SORT = "EVENTS_SORT"

export const ASSESSMENT_LINK_PLACEHOLDER = "[Assessment link]"
// eslint-disable-next-line no-template-curly-in-string
export const LINK_PLACEHOLDER = "${link}"

export const TERTIARY_EDUCATION = "206"
export const WESTERN_AUSTRALIA_COUNTRY_ID = "09"
export const QUALIFICATION_OTHER_ID = "17"
export const ATAR_EXAMINATION = "ATAR Examinations"

export const ENTRANCE_SCORE_TYPE_OTHER = "OTHER"

export const EXTRA_ASSESSMENT_TIME = "% extra assessment time"
export const REQUIRES_EXTRA_TIME = "Requires extra time"
export const METRIC_SCORES_TO_IGNORE = [
  EXTRA_ASSESSMENT_TIME,
  REQUIRES_EXTRA_TIME,
]
export const SEPARATOR = ":::"
export const SOVA_PROJECT_CODE = "SOVA Project Code"

export const DEPARTMENT = "department"
export const LOCATION = "location"
export const CUSTOM = "custom"

export const TAGS = {
  DEPARTMENT,
  LOCATION,
  CUSTOM,
}

export const SOVA_PROJECT_CODES_KEY = "SOVA Project Codes"

export const UNIVERSITY_GROUPS_OZ = [
  {
    id: "all",
    value: "Specific universities",
  },
  {
    id: "go8",
    value: "Go8 universities",
  },
]

export const UNIVERSITY_GROUPS_UK = [
  {
    id: "all",
    value: "Specific universities",
  },
  {
    id: "oxbridge",
    value: "Oxbridge",
  },
  {
    id: "russell",
    value: "Russell Group",
  },
  {
    id: "nonRussell",
    value: "Non-Russell Group",
  },
]

export const RARE_ORGANIZATION_ID = "1"

export const DISTINCTION_DEGREE_CLASS_ID = "7"
export const MERIT_DEGREE_CLASS_ID = "8"
